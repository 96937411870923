import { createTheme } from "@mui/material";
import { grey, red } from "@mui/material/colors";

const getDesignTokens = () => ({
  palette: {
    primary: {
      main1: "#FFFFFF",
      main: grey[50],
      opacity80: "rgba(250, 250, 250, 0.85)",
      opacity95: "rgba(250, 250, 250, 0.95)",
    },
    secondary: {
      main: grey[100],
    },
    third: {
      main: grey[200],
    },
    forth: {
      main: grey[300],
    },
    fifth: {
      main: grey[400],
    },
    customRed: {
      main: red[400],
    },
    customOppositeLighter: {
      main: grey[500],
    },
    customOpposite: {
      main: grey[900],
    },
    divider: grey[400],
    text: {
      primary: grey[900],
      secondary: grey[700],
    },
  },
});

export const themeCreation = () =>
  createTheme({
    ...getDesignTokens(),
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 20,
              fontSize: "0.85em",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: grey[900], 
            color: grey[50],
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            backgroundColor: grey[900],
          },
          track: {
            backgroundColor: grey[700], 
          },
          rail: {
            backgroundColor: grey[900], 
          },
        },
      },
    },
  });
