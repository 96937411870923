// src/helpers/filterUtils.js
import { getTranslation } from './translationUtils'; 

export const createFilterArray = (language = 'en') => [
  {
    title: getTranslation('filters.tags', language),
    filters: [
      { label: getTranslation('filters.new', language), value: 'new' },
      { label: getTranslation('filters.sale', language), value: 'sale' },
      { label: getTranslation('filters.women', language), value: 'women' },
      { label: getTranslation('filters.man', language), value: 'man' },
      { label: getTranslation('filters.kids', language), value: 'kids' },
    ],
  },
  {
    title: getTranslation('filters.price', language),
    price: true,
  },
  {
    title: getTranslation('filters.color', language),
    filters: [
      { label: getTranslation('filters.colors.red', language), value: 'red' },
      { label: getTranslation('filters.colors.blue', language), value: 'blue' },
      { label: getTranslation('filters.colors.green', language), value: 'green' },
      { label: getTranslation('filters.colors.black', language), value: 'black' },
      { label: getTranslation('filters.colors.white', language), value: 'white' },
      { label: getTranslation('filters.colors.yellow', language), value: 'yellow' },
      { label: getTranslation('filters.colors.purple', language), value: 'purple' },
      { label: getTranslation('filters.colors.orange', language), value: 'orange' },
      { label: getTranslation('filters.colors.brown', language), value: 'brown' },
      { label: getTranslation('filters.colors.gray', language), value: 'gray' },
    ],
  },
  {
    title: getTranslation('filters.heelHeight', language),
    filters: [
      { label: '6.5', value: '6.5' },
      { label: '7.5', value: '7.5' },
      { label: '8.5', value: '8.5' },
      { label: '9.5', value: '9.5' },
    ],
  },
  {
    title: getTranslation('filters.size', language),
    filters: [
      { label: '35', value: '35' },
      { label: '36', value: '36' },
      { label: '37', value: '37' },
      { label: '38', value: '38' },
      { label: '39', value: '39' },
      { label: '40', value: '40' },
      { label: '41', value: '41' },
    ],
  },
];
