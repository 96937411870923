import "./header-components.css";
import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { translations } from "../../../helpers/translations";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { selectActiveLanguage } from "../../../store/reducers/userSlice";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";

function SearchInput({ value, onChange }) {
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};

  return (
    <div className="search-input-container" style={{ height: isSmallScreen ? "" : "50px" }}>
      <TextField
        fullWidth
        type="text"
        size="small"
        placeholder={translatedLanguages.search}
        onChange={onChange}  
        value={value} 
        color="customOpposite"
        InputProps={{
          className: "text-field-input",
          sx: {
            height: isSmallScreen ? 25 : 30,
            width: isSmallScreen ? 240 : "100%",
          },
          endAdornment: (
            <>
              {value && (
                <InputAdornment position="end">
                  <ClearIcon
                    onClick={() => onChange({ target: { value: "" } })}  
                    className="icon-cursor"
                    sx={{ fontSize: isSmallScreen ? "0.8em" : "1em" }}
                  />
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <Divider
                  className="divider"
                  sx={{
                    height: isSmallScreen ? "50%" : "80%",
                  }}
                  orientation="vertical"
                  variant="middle"
                />
                <SearchIcon
                  className="icon-cursor"
                  sx={{
                    fontSize: isSmallScreen ? "1.1em" : "1.3em",
                    margin: "0px 5px",
                  }}
                />
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  );
}

export default SearchInput;
