import { useTheme } from "@emotion/react";
import "./header-components.css";
import CloseIcon from "@mui/icons-material/Close";
import LanguageModalMobile from "./LanguageModalMobile";

function MenuMobileModal({ onClose }) { 
    const theme = useTheme();

	return (
		<div
			className="menu-modal-container"
			style={{
				top: "85px",
				right: "0",
				width: "100%",
				minHeight: "90vh",
				backgroundColor: theme.palette.primary.main1,
				border: `1px solid ${theme.palette.customOppositeLighter.main}`,
				fontSize: "1em",
			}}
		>
			<CloseIcon
				onClick={onClose}
				className="close-icon"
				style={{
					color: theme.palette.text.primary,
					position: "absolute",
					fontSize: "1.2em !important",
				}}
			/>
			<LanguageModalMobile/>
		</div>
	);
}

export default MenuMobileModal;
