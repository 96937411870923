
import { translations } from './translations'; 

export const getTranslation = (key, language = 'en') => {
  const keys = key.split('.');
  let translation = translations[language];

  keys.forEach(k => {
    translation = translation?.[k];
  });

  return translation || key;
};
