import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
	name: "cart",
	initialState: {
		itemsCount: 0,
		items: [],
	},
	reducers: {
		incrementItemsCount(state) {
			state.itemsCount += 1;
		},
		addItemToCart(state, action) {
			const { product } = action.payload;

			// Use _id for checking if the product already exists in the cart
			const existingItem = state.items.find(
				(item) => item.product._id === product._id
			);

			if (existingItem) {
				existingItem.count += 1;
			} else {
				state.items.push({ count: 1, product });
			}

			state.itemsCount += 1;
		},
		decrementItemsCount(state) {
			state.itemsCount -= 1;
			if (state.itemsCount < 0) {
				state.itemsCount = 0;
			}
		},
		removeItemsFromCart(state, action) {
			const itemToRemoveId = action.payload.id;

			// Use _id for removing the product from the cart
			const existingItem = state.items.find(
				(item) => item.product._id === itemToRemoveId 
			);

			if (existingItem) {
				// Decrease item count if it exists
				existingItem.count -= 1;

				// If the item count reaches 0, remove the item from the cart
				if (existingItem.count <= 0) {
					state.items = state.items.filter(
						(item) => item.product._id !== itemToRemoveId
					);
				}

				// Adjust the total items count in the cart
				state.itemsCount -= 1;
				if (state.itemsCount < 0) {
					state.itemsCount = 0;
				}
			}
		}
	},
});

export const {
	incrementItemsCount,
	decrementItemsCount,
	addItemToCart,
	removeItemsFromCart,
} = cartSlice.actions;

export const selectItemsCount = (state) => {
	const items = state.cart.items;
	let count = 0;
	items.forEach((item) => {
		count += item.count;
	});
	return count;
};

const applyDiscount = (price, discountPercentage) => {
	return price - (price * discountPercentage) / 100;
};

export const selectTotalCartPrice = (state) => {
	const items = state.cart.items;
	let total = 0;
	items.forEach((item) => {
		const priceToUse = item.product.tags.includes('Sale')
			? applyDiscount(item.product.price, item.product.discountAmount)
			: item.product.price;
		
		total += priceToUse * item.count;
	});
	return Math.ceil(total);
};

export const selectItems = (state) => state.cart.items;

export default cartSlice.reducer;
