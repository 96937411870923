import React from "react";
import "./homepage.css";
import { selectScreenSize } from "../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ImageSlider from "../mainpage/ImageSlider";
import ProductsMainpage from "../mainpage/ProductsMainpage";
import AboutUs from "../mainpage/AboutUs";

function HomePage() {
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;

	return (
		<div className="homepage-container">
			<ImageSlider />
			<ProductsMainpage />
			<AboutUs />
		</div>
	);
}

export default HomePage;
