import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectActiveLanguage,
	setLanguage,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import LanguageOption from "./LanguageOption";
import { Divider } from "@mui/material";

function LanguageModalMobile() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};

	const languageOptions = [
		{ code: "en", label: translatedLanguages.english || "English" },
		{ code: "sr", label: translatedLanguages.serbian || "Serbian" },
	];

	const onLanguageSelect = (language) => {
		dispatch(setLanguage({ language }));
	};

	const activeLanguage =
		currentLanguage === "en"
			? translatedLanguages.english
			: currentLanguage === "sr"
			? translatedLanguages.serbian
			: "Unknown Language";

	return (
		<div className="language-mobile-modal-container">
			<div className="language-mobile-modal-link-container">
				<div className="language-mobile-modal-link">
					{translatedLanguages.aboutUs}
				</div>
				<div className="language-mobile-modal-link">
					{translatedLanguages.shop}
				</div>
				<div className="language-mobile-modal-link">
					{translatedLanguages.contact}
				</div>
			</div>
			<div className="language-mobile-modal">
				{languageOptions.map(({ code, label }, index) => (
					<>
						<LanguageOption
							key={code}
							language={{ code, label }}
							onClick={() => onLanguageSelect(code)}
						/>

						{index < languageOptions.length - 1 && (
							<Divider
								style={{
									backgroundColor: theme.palette.customOppositeLighter.main,
								}}
								orientation="vertical"
								variant="middle"
								flexItem
							/>
						)}
					</>
				))}
			</div>
			<div className="language-mobile-modal-label">{activeLanguage}</div>
		</div>
	);
}

export default LanguageModalMobile;
