import "./header-components.css";
import { isMobile } from "react-device-detect";
import { Divider, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
	addItemToCart,
	removeItemsFromCart,
	selectItems,
	selectTotalCartPrice,
} from "../../../store/reducers/cartSlice";
import { displayNotification } from "../../../store/reducers/notificationSlice";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import { NOTIFICATION_TYPES } from "../../../helpers/app.constants";

function CartModal({ onClose }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const modalClassName = isSmallScreen
		? "cart-modal-container"
		: "cart-modal-container animate-slide-in";

	const items = useSelector(selectItems);
	const totalPrice = useSelector(selectTotalCartPrice);
	const cartEmpty = items.length === 0;

	const addToCartHandler = (item) => {
		dispatch(addItemToCart({ product: item.product }));
		dispatch(
			displayNotification({
				text: `${item.product.name} ${translatedLanguages.addedToCart}!`,
				type: NOTIFICATION_TYPES.SUCCESS,
			}),
		);
	};

	const removeFromCartHandler = (item) => {
		dispatch(removeItemsFromCart({ id: item.product._id })); 
		dispatch(
			displayNotification({
				text: `${item.product.name} ${translatedLanguages.removedFromCart}!`,
				type: NOTIFICATION_TYPES.ERROR,
			}),
		);
	};

	return (
		<div
			className={modalClassName}
			style={{
				top: isSmallScreen ? "85px" : "85px",
				right: isSmallScreen ? "0" : "0",
				width: isSmallScreen ? "100%" : "25vw",
				minHeight: isSmallScreen ? "90vh" : "calc(100vh - 85px)",
				backgroundColor: theme.palette.primary.main1,
				border: `1px solid ${theme.palette.customOppositeLighter.main}`,
				borderRight: "none",
				fontSize: isSmallScreen ? "1em" : "0.8em",
				justifyContent: cartEmpty ? "center" : "space-between",
				alignItems: cartEmpty ? "center" : "flex-start",
			}}
		>
			<CloseIcon
				onClick={onClose}
				className="close-icon"
				style={{
					color: theme.palette.text.primary,
					position: "absolute",
					fontSize: isSmallScreen ? "1.2em !important" : "2em !important",
				}}
			/>
			<div
				className="cart-modal-content"
				style={{
					width: isSmallScreen ? " 100%" : "90%",
					padding: isSmallScreen ? "7vh 0" : "5vh 10px",
				}}
			>
				{cartEmpty ? (
					<div className="cart-empty-message">
						{translatedLanguages.cartEmpty}
					</div>
				) : (
					<>
						{items.map((item, index) => {
							console.log(item); // Log the item properly here
							return (
								<div className="cart-item-container">
									<div key={index} className="cart-item">
										<div className="cart-item-info">
											<img
												src={item.product.image}
												alt={item.product.name}
												className="cart-item-image"
											/>
											<div className="cart-item-title">{item.product.name}</div>
										</div>
										<span className="cart-item-count">x {item.count}</span>
										<div className="cart-item-actions">
											<Tooltip title={translatedLanguages.remove}>
												<RemoveOutlinedIcon
													onClick={() => removeFromCartHandler(item)}
													className="cart-item-action-icon"
												/>
											</Tooltip>

											<Tooltip title={translatedLanguages.add}>
												<AddOutlinedIcon
													onClick={() => addToCartHandler(item)}
													className="cart-item-action-icon"
												/>
											</Tooltip>
										</div>
									</div>
									<Divider
										sx={{
											width: "88%",
											backgroundColor: theme.palette.customOppositeLighter.main,
											margin: "auto",
										}}
										orientation="horizontal"
										variant="middle"
										flexItem
									/>
								</div>
							);
						})}
					</>
				)}
			</div>
			{!cartEmpty && (
				<div
					style={{
						color: theme.palette.customOpposite.main,
						backgroundColor: theme.palette.primary.main1,
						border: `1px solid ${theme.palette.customOppositeLighter.main}`,
						borderLeft: "none",
						borderBottom: "none",
					}}
					className="cart-total-price"
				>
					<div
						className="cart-price"
						style={{ padding: isSmallScreen ? "5vh 0" : "10px" }}
					>
						{translatedLanguages.total}
						&nbsp;
						<div style={{ color: "#00AC11" }}>{totalPrice},00&nbsp;</div>RSD
					</div>
				</div>
			)}
		</div>
	);
}

export default CartModal;
