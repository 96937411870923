import "./header-components.css";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { Divider } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import Badge from "@mui/material/Badge";
import LanguageSelect from "./LanguageSelect";
import CartModal from "./CartModal";
import LanguageModalMobile from "./LanguageModalMobile";
import MenuMobileSelect from "./MenuMobileSelect";
import { selectItemsCount } from "../../../store/reducers/cartSlice";

function HeaderIcons() {
	const theme = useTheme();
	const itemsCount = useSelector(selectItemsCount);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const isMediumSmaller = screenSize === "medium-s";
	const isMediumScreen = screenSize === "medium";

	const handleCartClick = () => {
		setIsCartOpen((prevState) => !prevState);
		if (!isCartOpen) {
			setIsLanguageModalOpen(false);
		}
	};

	const handleLanguageModalToggle = (isOpen) => {
		setIsLanguageModalOpen(isOpen);
		if (isOpen) {
			setIsCartOpen(false);
		}
	};

	const handleCartClose = () => {
		setIsCartOpen(false);
	};

	return (
		<div
			className="header-icons-container"
			style={{
				flex: isSmallScreen ? "0 0 30%" : "0 0 15%",
			}}
		>
			<Badge
				badgeContent={itemsCount}
				sx={{
					"& .MuiBadge-badge": {
						fontSize: "1em", 
						backgroundColor: theme.palette.customRed.main, 
						color: "white", 
						width: "24px",
						height: "24px", 
						borderRadius: "50%", 
					},
				}}
			>
				{isCartOpen ? (
					<ShoppingBagTwoToneIcon
						onClick={handleCartClick}
						style={{
							fontSize: "2em",
							cursor: "pointer",
							color: theme.palette.customOpposite.main,
						}}
					/>
				) : (
					<ShoppingBagOutlinedIcon
						onClick={handleCartClick}
						style={{
							fontSize: "2em",
							cursor: "pointer",
							color: theme.palette.customOpposite.main,
						}}
					/>
				)}
			</Badge>

			<Divider
				style={{
					backgroundColor: theme.palette.customOppositeLighter.main,
				}}
				orientation="vertical"
				variant="middle"
				flexItem
			/>
			{isSmallScreen ? (
				<MenuMobileSelect />
			) : (
				<LanguageSelect
					isModalOpen={isLanguageModalOpen}
					onToggleModal={handleLanguageModalToggle}
				/>
			)}

			{isCartOpen && <CartModal onClose={handleCartClose} />}
		</div>
	);
}

export default HeaderIcons;
