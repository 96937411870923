import { isMobile } from "react-device-detect";
import "./products-mainpage.css";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import { useSelector, useDispatch } from "react-redux";
import useGetAllProducts from "../../helpers/hooks/useGetAllProducts";
import ProductContainer from "../other/ProductContainer";
import {
	selectDataState,
	setDataState,
} from "../../store/reducers/notificationSlice";
import { DATA_STATE } from "../../helpers/app.constants";
import { translations } from "../../helpers/translations";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function ProductsMainpage() {
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dataState = useSelector(selectDataState);
	const products = useGetAllProducts(false);
	const productsEmpty = !products || products.length === 0;
	const isMedium = useMediaQuery("(max-width: 1500px)");

	useEffect(() => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	}, [dispatch]);

	useEffect(() => {
		if (products) {
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
		}
	}, [products, dispatch]);

	if (dataState === DATA_STATE.DATA_STATE_LOADING) return null;

	const newProducts = products
		?.filter((product) => {
			const hasNewTag = product.tags
				.map((tag) => tag.toLowerCase())
				.includes("new");
			return hasNewTag;
		})
		.sort((a, b) => (b._id > a._id ? 1 : -1)) 
		.slice(0, 4); 

	
	return (
		<div
			className="products-mainpage-container"
			style={{
				marginLeft: isSmallScreen ? "" : "50px",
				width: isSmallScreen ? "100%" : "calc(100% - 50px)",
				minHeight: isSmallScreen ? "65vh" : "85vh",
				height: "fit-content",
				flexDirection: isSmallScreen ? "column" : "row",
				padding: isSmallScreen ? "10vh 0vh" : "5vh 0",
				display: productsEmpty ? "flex" : "grid",
			}}
		>
			{!newProducts || productsEmpty ? (
				<p>{translatedLanguages.noProducts || "No products available"}</p>
			) : (
				newProducts.map((product, index) => (
					<ProductContainer
						className="product-container"
						key={product._id || index}
						product={product}
					/>
				))
			)}
			<div
				className="products-allproducts-link"
				style={{
					bottom: isSmallScreen ? "4vh" : isMedium ? "0vh" : "15vh",
				}}
				onClick={() => navigate("/shop")}
			>
				{translatedLanguages.viewAllProducts} <span>&gt;</span>
			</div>
		</div>
	);
}

export default ProductsMainpage;
