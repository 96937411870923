import "./header-components.css";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { ReactComponent as EnglishIcon } from "../../../media/language/english.svg";
import { ReactComponent as SerbianIcon } from "../../../media/language/serbian.svg";

const languageIcons = {
	en: EnglishIcon,
	sr: SerbianIcon,
};

function LanguageOption({ language, onClick }) {
	const Icon = languageIcons[language.code] || EnglishIcon;
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;

	return (
		<div
			className={
				isSmallScreen
					? "language-option-single-mobile"
					: "language-option-single"
			}
			onClick={onClick}
		>
			{!isSmallScreen && (
				<div className="language-option-single-label">{language.label}</div>
			)}
			&nbsp;
			<div
				className={
					isSmallScreen ? "language-mobile-option" : "language-desktop-option"
				}
			>
				<Icon className="language-desktop-selected-option-icons" />
			</div>
		</div>
	);
}

export default LanguageOption;
