import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";  // Ensure you have axios installed or use fetch
import {
  setDataState,
  displayNotification,
} from "../../store/reducers/notificationSlice";
import { DATA_STATE, NOTIFICATION_TYPES, SERVER_URL } from "../app.constants";
import { setAllProducts } from "../../store/reducers/productSlice";


const useGetAllProducts = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState(null); 

  useEffect(() => {
    dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

    const fetchAllProducts = async () => {
      try {
        // Fetch products from the backend
        const response = await axios.get(`${SERVER_URL}product/getallproducts`);
        setProducts(response.data.products);  // Set the products to state
        dispatch(setAllProducts(response.data.products));  // Dispatch to Redux store
      } catch (error) {
        // Handle errors by showing a notification
        const notificationPayload = {
          text: "Failed to load products from server!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
      } finally {
        // Set data state as OK
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      }
    };

    // Initiate product fetching from the backend
    fetchAllProducts();
  }, [dispatch]);

  return products;
};

export default useGetAllProducts;
