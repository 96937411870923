import "./filter-component.css";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import TuneIcon from "@mui/icons-material/Tune";
import FilterExpanded from "./components/FilterExpanded";

function FilterComponent() {
	const theme = useTheme();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleClickExpanded = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const filterComponentStyles = {
		expanded: {
			backgroundColor: theme.palette.secondary.main,
			border: "none",
		},
		icon: {
			fontSize: "1.75em",
			cursor: "pointer",
			color: theme.palette.customOpposite.main,
		},
		iconExpanded: {
			transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
		},
	};

	return (
		<div
			className="filter-bar"
			style={{
				borderRight: `1px solid ${theme.palette.customOppositeLighter.main}`,
				backgroundColor: theme.palette.primary.main1,
			}}
		>
			<div className="filter-divider" onClick={handleClickExpanded}>
				<TuneIcon
					style={{
						...filterComponentStyles.icon,
						...filterComponentStyles.iconExpanded,
					}}
				/>
			</div>
			<div className="filter-divider" onClick={handleClickExpanded}>
				<KeyboardArrowRightTwoToneIcon
					style={{
						...filterComponentStyles.icon,
						...filterComponentStyles.iconExpanded,
					}}
				/>
			</div>
			{isExpanded && (
				<div
					className="expanded-filter"
					style={filterComponentStyles.expanded}
				>
					<FilterExpanded />
				</div>
			)}
		</div>
	);
}

export default FilterComponent;
