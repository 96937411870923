import { Breadcrumbs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbStyle = {
	color: "black",
	cursor: "pointer",
	"&:hover": {
		color: "grey",
		textDecoration: "underline",
	},
};

function Bredcrumbs({ pageName, pageLink, pageName2, pageLink2 }) {
    const navigate = useNavigate();
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<p style={breadcrumbStyle} onClick={() => navigate("/")}>
				Home
			</p>

			<p style={breadcrumbStyle} onClick={() => navigate(pageLink)}>
				{pageName}
			</p>
			{pageName2 && (
				<p style={breadcrumbStyle} onClick={() => navigate(pageLink2)}>
					{pageName2}
				</p>
			)}
		</Breadcrumbs>
	);
}

export default Bredcrumbs;
