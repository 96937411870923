import "./header.css";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import SearchInput from "./components/SearchInput";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import HeaderIcons from "./components/HeaderIcons";
import FilterExpanded from "../filterComponent/components/FilterExpanded";
import { ReactComponent as Logo } from "../../media/logo/logo.svg";
import { translations } from "../../helpers/translations";

function Header() {
	const theme = useTheme();
	const navigate = useNavigate();
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const isMediumSmaller = screenSize === "medium-s";
	const isMediumScreen = screenSize === "medium";
	const showSmallSearch = isSmallScreen;
	const [filtersExpanded, setFiltersExpanded] = useState(false);
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};

	const toggleFilters = () => {
		setFiltersExpanded((prev) => !prev);
	};

	return (
		<div className="heading-container">
			<div
				className="header-container"
				style={{
					backgroundColor: theme.palette.primary.main1,
					borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
				}}
			>
				<div className="header-logo-container">
					<Logo
						onClick={() => navigate("/")}
						className="header-logo"
						style={{ width: isSmallScreen ? "100px" : "120px" }}
					/>
				</div>
				{/* {!isSmallScreen && (
					<div
						className="header-links-container"
						style={{
							flex: isSmallScreen ? "" : "0 0 60%",
						}}
					>
						<div className="header-links-inner-container">
						<p  className="header-links">{translatedLanguages.aboutUs}</p>
						<p className="header-links">{translatedLanguages.shop}</p>
						<p className="header-links">{translatedLanguages.contact}</p>
						</div>
					</div>
				)} */}
				<HeaderIcons />
			</div>

			{showSmallSearch && (
				<div
					className="mobile-search-container"
					style={{
						borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
						backgroundColor: theme.palette.primary.main1,
					}}
				>
					<div className="back-icon-container"></div>

					<div
						className="mobile-search"
						onClick={toggleFilters}
						onTouchStart={toggleFilters}
					>
						<SearchInput />
					</div>

					<div
						className="back-icon-container"
						onClick={toggleFilters}
						onTouchStart={toggleFilters}
					>
						{!filtersExpanded ? (
							<TuneIcon
								className="icon-hover"
								style={{
									color: theme.palette.customOpposite.main,
									fontSize: "1.2em",
								}}
							/>
						) : (
							<CloseIcon
								className="icon-hover"
								style={{
									color: theme.palette.customOpposite.main,
									fontSize: "1.2em",
								}}
							/>
						)}
					</div>
				</div>
			)}

			{showSmallSearch && filtersExpanded && (
				<div className="small-screen-filter-container ">
					<FilterExpanded />
				</div>
			)}
		</div>
	);
}

export default Header;
