import React from "react";
import "./shoppage.css";
import Bredcrumbs from "../other/Bredcrumbs";
import ProductContainer from "../other/ProductContainer";
import { useSelector } from "react-redux";
import { selectActiveLanguage, selectScreenSize } from "../../store/reducers/userSlice";
import { selectFilteredProducts } from "../../store/reducers/filterSlice";
import { translations } from "../../helpers/translations.js";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "@mui/material";

function ShopPage() {
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const filteredProducts = useSelector(selectFilteredProducts);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const isMedium = useMediaQuery("(max-width: 1500px)");

	const productsEmpty = !filteredProducts || filteredProducts.length === 0;
    console.log("isSmallScreen:", isSmallScreen, "productsEmpty:", productsEmpty);

	return (
		<div
			className="shoppage-container"
			style={{
				minHeight: isSmallScreen ? "65vh" : "85vh",
				height: "fit-content",
				width: isSmallScreen ? "100%" : "calc(100% - 60px)",
			}}
		>
			<Bredcrumbs pageName={translatedLanguages.shop} pageLink="/shop" />

			<div
				className="shoppage-filtered-container"
				style={{
                    display: isSmallScreen ? "flex" : productsEmpty ? "flex" : "grid",
					height: productsEmpty ? "80vh" : "fit-content",
                    flexDirection: isSmallScreen ? "column" : "row",
                    paddingBottom: "8vh"
				}}
			>
				{productsEmpty ? (
					<p>{translatedLanguages.noProducts || "No products available"}</p>
				) : (
					filteredProducts.map((product, index) => (
						<ProductContainer
							className="product-container"
							key={product._id || index}
							product={product}
						/>
					))
				)}
			</div>
		</div>
	);
}

export default ShopPage;
