import "./header-components.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import MenuMobileModal from "./MenuMobileModal";

function MenuMobileSelect() {
	const theme = useTheme();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenuClick = () => {
		setIsMenuOpen((prevState) => !prevState);
	};

    const handleClose = () => {
        setIsMenuOpen(false); 
    };

	return (
		<div className="menu-mobile-select-container">
			<MenuIcon
				onClick={handleMenuClick}
				style={{
					fontSize: "2em",
					cursor: "pointer",
					color: theme.palette.customOpposite.main,
				}}
			/>
			{isMenuOpen && <MenuMobileModal onClose={handleClose} />} 
		</div>
	);
}



export default MenuMobileSelect;
