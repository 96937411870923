import "./product-container.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { addItemToCart } from "../../store/reducers/cartSlice";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { NOTIFICATION_TYPES } from "../../helpers/app.constants";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import { translations } from "../../helpers/translations.js";
import { isMobile } from "react-device-detect";

export function applyDiscount(price, discountPercentage) {
	return price - (price * discountPercentage) / 100;
}

function ProductContainer({ product }) {
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const dispatch = useDispatch();
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};

	const [isHoveredCart, setIsHoveredCart] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const images = [product.image, product.image2, product.image3].filter(Boolean);

	const handleMouseEnterImage = () => setCurrentImageIndex(1);
	const handleMouseLeaveImage = () => setCurrentImageIndex(0);
	const handleNextImage = () =>
		setCurrentImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
	const handlePrevImage = () =>
		setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));

	let hoverTimeout;
	const handleMouseEnterCart = () => {
		clearTimeout(hoverTimeout);
		hoverTimeout = setTimeout(() => setIsHoveredCart(true), 10);
	};

	const handleMouseLeaveCart = () => {
		clearTimeout(hoverTimeout);
		hoverTimeout = setTimeout(() => setIsHoveredCart(false), 10);
	};

	const handleAddProductClick = () => {
		dispatch(addItemToCart({ product }));
		dispatch(
			displayNotification({
				text: `${product.name} ${translatedLanguages.addedToCart}!`,
				type: NOTIFICATION_TYPES.SUCCESS,
			}),
		);
	};

	if (!product.tags.includes("new")) return null;

	const discountedPrice = product.tags.includes("Sale")
		? applyDiscount(product.price, product.discountAmount)
		: product.price;

	return (
		<div
			className="product-container"
			style={{
				padding: isSmallScreen ? "2vh 0" : "0 2vh",
				maxWidth: isSmallScreen ? "280px" : "400px",
			}}
		>
			<div
				className="image-wrapper"
				onMouseEnter={handleMouseEnterImage}
				onMouseLeave={handleMouseLeaveImage}
			>
				<img
					src={images[currentImageIndex]}
					alt={product.name}
					className="product-image"
				/>

				<div className="prev-button" onClick={handlePrevImage}>
					<ArrowBackIosIcon />
				</div>

				<div className="next-button" onClick={handleNextImage}>
					<ArrowForwardIosIcon />
				</div>
			</div>

			<h3 className="product-title">
				{product.name}
				<span
					className="tag-new"
					style={{ padding: isSmallScreen ? "2px" : "5px" }}
				>
					{translatedLanguages.filters.new}
				</span>
			</h3>

			<div className="price-cart-container">
				<p className="product-price">
					{product.tags.includes("Sale") && (
						<>
							<span className="strikethrough">{product.price}</span>
							<span>{discountedPrice} RSD</span>
						</>
					)}
					{!product.tags.includes("Sale") && <span>{product.price} RSD</span>}
				</p>

				<Tooltip title={translatedLanguages.addToCart} placement="bottom">
					<div
						className="cart-icon-container"
						onMouseEnter={handleMouseEnterCart}
						onMouseLeave={handleMouseLeaveCart}
						onClick={handleAddProductClick}
					>
						{isHoveredCart ? (
							<ShoppingCartIcon
								style={{
									fontSize: "2em",
									color: "grey",
									transition:
										"color 0.3s ease-in-out, transform 0.3s ease-in-out",
								}}
							/>
						) : (
							<ShoppingCartOutlinedIcon
								sx={{
									fontSize: "2em",
									color: "#aaa",
									transition:
										"color 0.3s ease-in-out, transform 0.3s ease-in-out",
								}}
							/>
						)}
					</div>
				</Tooltip>
			</div>
		</div>
	);
}

export default ProductContainer;
