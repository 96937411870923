import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filters',
  initialState: {
    filteredProducts: [],
  },
  reducers: {
    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload; 
    },
  },
});

export const { setFilteredProducts } = filterSlice.actions;
export const selectFilteredProducts = (state) => state.filter.filteredProducts;
export default filterSlice.reducer;
