import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	setLanguage,
	LANGUAGE_OPTIONS,
	selectScreenSize,
} from "../../../store/reducers/userSlice";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { ReactComponent as EnglishIcon } from "../../../media/language/english.svg";
import { ReactComponent as SerbianIcon } from "../../../media/language/serbian.svg";
import LanguageModal from "./LanguageModal";
import LanguageModalMobile from "./LanguageModalMobile";

const languageIcons = {
	[LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
	[LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function LanguageSelect({ isModalOpen, onToggleModal }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const currentLanguage = useSelector((state) => state.user.language);
	const modalRef = useRef(null);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;

	const handleLanguageChange = (language) => {
		dispatch(setLanguage({ language }));
		onToggleModal(false); 
	};

	const toggleModal = () => {
		onToggleModal(!isModalOpen); 
	};

	const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

	return (
		<div className="language-desktop-select">
			<div
				className="language-desktop-selected"
				onClick={toggleModal}
				style={{
					border: `2px solid ${theme.palette.customOpposite.main}`,
				}}
			>
				<CurrentLanguageIcon className="language-desktop-selected-icon" />
			</div>
			{isModalOpen && (
				<div ref={modalRef} className="modal-container">
						<LanguageModal
							onLanguageSelect={handleLanguageChange}
							CurrentLanguageIcon={CurrentLanguageIcon}
							onClose={() => onToggleModal(false)}
						/>
				</div>
			)}
		</div>
	);
}

export default LanguageSelect;
