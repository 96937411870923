import "../filter-component.css";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Input, Slider } from "@mui/material";
import { Formik, Field, Form } from "formik";
import { createFilterArray } from "../../../helpers/filterUtils";
import SearchInput from "../../header/components/SearchInput";
import colorMap from "../../../helpers/filterColorMap.js";
import { getTranslation } from "../../../helpers/translationUtils.js";
import { useDispatch, useSelector } from "react-redux";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../../store/reducers/userSlice.js";
import axios from "axios";
import { SERVER_URL } from "../../../helpers/app.constants.js";
import { setFilteredProducts } from "../../../store/reducers/filterSlice.js";
import { useNavigate } from "react-router-dom";
import {
	displayNotification,
	setDataState,
} from "../../../store/reducers/notificationSlice";
import { translations } from "../../../helpers/translations";
import { NOTIFICATION_TYPES, DATA_STATE } from "../../../helpers/app.constants";

function FiltersFormik({ filterReset, setFilterReset, setSubmitForm }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const language = useSelector(selectActiveLanguage);
	const filterArray = createFilterArray(language);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};

	const initialValues = {
		filters: [],
		minPriceFormik: 0,
		maxPriceFormik: 10000,
		searchQuery: "",
	};

	// Price states
	const [minPrice, setMinPrice] = useState(initialValues.minPriceFormik);
	const [maxPrice, setMaxPrice] = useState(initialValues.maxPriceFormik);

	// Effect to handle resetting filters when filterReset changes
	useEffect(() => {
		if (filterReset) {
			setMinPrice(initialValues.minPriceFormik);
			setMaxPrice(initialValues.maxPriceFormik);
			setFilterReset(false);
		}
	}, [filterReset, setFilterReset]);

	// Effect to allow external form submission control
	useEffect(() => {
		if (setSubmitForm) {
			setSubmitForm(() => formikRef.current.submitForm);
		}
	}, [setSubmitForm]);

	// Formik reference to access submitForm externally
	const formikRef = React.useRef(null);

	const handleSubmit = async (values) => {
		dispatch(
			displayNotification({
				text: translatedLanguages.filtering || "Filtering products...",
				type: NOTIFICATION_TYPES.LOADING,
			}),
		);
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		const filtersPayload = {
			color: values.filters.find((f) =>
				[
					"red",
					"blue",
					"green",
					"black",
					"white",
					"yellow",
					"purple",
					"orange",
					"brown",
					"gray",
				].includes(f),
			),

			tags: values.filters.filter((f) =>
				["new", "sale", "women", "man", "kids"].includes(f),
			),

			heelHeight: values.filters.filter((f) =>
				["6.5", "7.5", "8.5", "9.5"].includes(f),
			),

			size: values.filters.filter((f) =>
				["35", "36", "37", "38", "39", "40", "41"].includes(f),
			),

			minPrice: values.minPriceFormik,
			maxPrice: values.maxPriceFormik,
			searchQuery: values.searchQuery,
		};

		try {
			const response = await axios.post(
				`${SERVER_URL}product/filterproducts`,
				filtersPayload,
			);
			dispatch(setFilteredProducts(response.data.products));
			dispatch(
				displayNotification({
					text: translatedLanguages.filteringSuccess || "Filtering complete!",
					type: NOTIFICATION_TYPES.SUCCESS,
				}),
			);
			navigate("/shop");
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			console.log("Filtered products:", response.data);

		} catch (error) {
			dispatch(
				displayNotification({
					text:
						translatedLanguages.filteringError || "Error filtering products",
					type: NOTIFICATION_TYPES.ERROR,
				}),
			);
			console.error("Error filtering products:", error);
		}
	};

	return (
		<Formik
			innerRef={formikRef}
			initialValues={initialValues}
			onSubmit={handleSubmit}
		>
			{({ values, setFieldValue, submitForm }) => (
				<Form
					className="formik-container"
					style={{
						backgroundColor: theme.palette.primary.main1,
						borderRight: `1px solid ${theme.palette.customOppositeLighter.main}`,
						marginLeft: isSmallScreen ? "" : "1.5px",
					}}
				>
					<div className="filter-expanded-content">
						{!isSmallScreen && (
							<div className="filter-search-container">
								<div className="filter-search">
									<SearchInput
										value={values.searchQuery}
										onChange={(e) => {
											const searchValue = e.target.value;
											setFieldValue("searchQuery", searchValue);
											submitForm(); // Submit form on search input change
										}}
									/>
								</div>
								<Divider
									sx={{
										width: "85%",
										backgroundColor: theme.palette.customOppositeLighter.main,
										margin: "auto",
									}}
									orientation="horizontal"
									variant="middle"
									flexItem
								/>
							</div>
						)}

						{/* Iterate through filters */}
						{filterArray.map((item, index) => (
							<div className="expanded-content-map" key={index}>
								<div className="filter-heading-formik">
									<p
										className="filter-title"
										style={{ color: theme.palette.customOpposite.main }}
									>
										{item.title}
									</p>

									{/* Handle price slider separately */}
									{item.price ? (
										<div className="slider-container">
											<div className="slider-title-container">
												<div className="slider-title">
													<div>Min:</div>
													<Input
														className="price-input"
														value={minPrice}
														onChange={(e) =>
															setMinPrice(Number(e.target.value) || 0)
														}
														inputProps={{
															step: 10,
															min: 0,
															max: maxPrice,
															type: "number",
															"aria-labelledby": "input-slider",
														}}
													/>
												</div>
												<div className="slider-title">
													<div>Max:</div>
													<Input
														className="price-input"
														value={maxPrice}
														onChange={(e) =>
															setMaxPrice(Number(e.target.value) || 0)
														}
														inputProps={{
															step: 10,
															min: minPrice,
															max: 10000,
															type: "number",
															"aria-labelledby": "input-slider",
														}}
													/>
												</div>
											</div>
											<div className="sliderbar-container">
												<Slider
													sx={{ mt: "20px" }}
													value={[minPrice, maxPrice]}
													onChange={(event, newValue) => {
														const [min, max] = newValue;
														setMinPrice(min);
														setMaxPrice(max);
														setFieldValue("minPriceFormik", min);
														setFieldValue("maxPriceFormik", max);
														submitForm();
													}}
													aria-labelledby="input-slider"
													min={0}
													max={10000}
												/>
											</div>
										</div>
									) : (
										item.filters.map((filter, filterIndex) => {
											const filterColor =
												item.title === getTranslation("filters.color", language)
													? colorMap[filter.value]
													: null;

											return (
												<div className="filter" key={filterIndex}>
													<p
														style={{ color: theme.palette.customOpposite.main }}
													>
														{filter.label}
													</p>
													<Field
														type="checkbox"
														name="filters"
														value={filter.value}
														as={Checkbox}
														sx={{
															"&.Mui-checked": {
																color:
																	filterColor ||
																	theme.palette.customOpposite.main,
																"& svg": {
																	stroke: filterColor ? "#808080" : null,
																},
															},
														}}
														size="small"
														checked={values.filters.includes(filter.value)}
														onChange={(e) => {
															const isChecked = e.target.checked;
															const updatedFilters = isChecked
																? [...values.filters, e.target.value]
																: values.filters.filter(
																		(value) => value !== e.target.value,
																  );

															setFieldValue("filters", updatedFilters);
															submitForm();
														}}
													/>
												</div>
											);
										})
									)}
								</div>

								{index !== filterArray.length - 1 && (
									<Divider
										sx={{
											width: "85%",
											backgroundColor: theme.palette.customOppositeLighter.main,
										}}
										orientation="horizontal"
										variant="middle"
										flexItem
									/>
								)}
							</div>
						))}
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default FiltersFormik;
