import { useSelector } from "react-redux";
import "./about-us.css";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import { translations } from "../../helpers/translations.js";
import aboutImage from "../../media/images/aboutimage.png";
import { useMediaQuery } from "@mui/material";

function AboutUs() {
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const isSmallMedium = useMediaQuery("(max-width: 770px)");

	return (
		<div
			className="about-us"
			style={{
				marginLeft: isSmallScreen ? "" : "50px",
				width: isSmallScreen ? "100%" : "calc(100% - 50px)",
				height: isSmallScreen ? "fit-content" : "80vh",
				flexDirection: isSmallScreen || isSmallMedium ? "column" : "row",
				paddingBottom: isSmallScreen ? "8vh" : "5vh",
			}}
		>
			<div
				className="about-us-image"
				style={{ width: isSmallScreen ? "75%" : "35%" }}
			>
				<img
					src={aboutImage}
					alt="About Us"
					style={{ width: "100%", height: "100%", objectFit: "cover" }}
				/>
			</div>
			<div
				className="about-us-content"
				style={{
					width: isSmallScreen ? "70%" : "50%",
					height: isSmallScreen ? "fit-content" : "75%",
				}}
			>
				<div
					className="about-us-content-inner"
					style={{
						width: isSmallScreen ? "100%" : isSmallMedium ? "100%" : "80%",
					}}
				>
					<h1 style={{ fontSize: isSmallScreen ? "1.15em" : "" }}>
						{translatedLanguages.heading}
					</h1>
					<p
						style={{
							fontSize: isSmallScreen ? "1em" : "1.2em",
							textAlign: isSmallScreen || isSmallMedium ? "justify" : "justify",
						}}
						className="about-us-p"
					>
						{translatedLanguages.content}
					</p>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;
