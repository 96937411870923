import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import LanguageOption from "./LanguageOption";
import CloseIcon from "@mui/icons-material/Close";

function LanguageModal({ onLanguageSelect,onClose  }) {
	const theme = useTheme();
	const screenSize = useSelector(selectScreenSize);
	const currentLanguage = useSelector(selectActiveLanguage);

	const translatedLanguages = translations[currentLanguage] || {};

	const languageOptions = [
		{ code: "en", label: translatedLanguages.english || "English" },
		{ code: "sr", label: translatedLanguages.serbian || "Serbian" },
	];

	return (
		<div
			className="language-desktop-modal"
			style={{
				border: `1px solid ${theme.palette.customOppositeLighter.main}`,
				borderTop: "none",
				backgroundColor:
					screenSize === "small" || isMobile
						? undefined
						: theme.palette.primary.main1,
			}}
		>
			<CloseIcon
				onClick={onClose}
                className="close-icon"
				style={{
					color: theme.palette.text.primary, 
				}}
			/>
			{languageOptions.map(({ code, label }) => (
				<LanguageOption
					key={code}
					language={{ code, label }}
					onClick={() => onLanguageSelect(code)}
				/>
			))}
		</div>
	);
}

export default LanguageModal;
