export const translations = {
	en: {
		english: "English",
		serbian: "Serbian",
		search: "Search...",
		links: "Links",
		aboutUs: "About us",
		contact: "Contact",
		services: "Services",
		contactUs: "Contact Us",
		shop: "Shop",
		followUs: "Follow Us",
		salesInfo: "Sales info",
		privacyPolicy: "Privacy policy",
		terms: "Terms and conditions ",
		paymentMethods: "Payment Methods",
		resetFilters: "Reset",
		applyFilters: "Apply",
		cartEmpty: "Cart is empty.",
		addToCart: "Add to cart",
		noProducts: "No products available.",
		viewAllProducts: "View all products.",
		add: "Add",
		remove: "Remove",
		addedToCart: "added to cart",
		removedFromCart: "removed from cart",
		total: "Total:",
		filters: {
			tags: "Tags",
			price: "Price",
			color: "Color",
			heelHeight: "Heel Height",
			size: "Size",
			new: "New",
			sale: "Sale",
			women: "Women",
			man: "Man",
			kids: "Kids",
			colors: {
				red: "Red",
				blue: "Blue",
				green: "Green",
				black: "Black",
				white: "White",
				yellow: "Yellow",
				purple: "Purple",
				orange: "Orange",
				brown: "Brown",
				gray: "Gray",
			},
		},
		heading: "The Passion Behind the Steps",
		content:
			"At Soul and Sole, we believe that every dancer deserves shoes as graceful as their moves. Our collection is meticulously designed to blend elegance, comfort, and precision, ensuring that you can dance with confidence. Whether you’re performing on stage or perfecting your technique in the studio, our shoes support your passion every step of the way.",
	},
	sr: {
		english: "Engleski",
		serbian: "Srpski",
		search: "Pretraga...",
		links: "Linkovi",
		aboutUs: "O nama",
		contact: "Kontakt",
		services: "Usluge",
		contactUs: "Kontaktirajte Nas",
		shop: "Prodavnica",
		followUs: "Pratite Nas",
		salesInfo: "Informacije o prodaji",
		privacyPolicy: "Politika privatnosti",
		terms: "Opšti uslovi kupovine ",
		paymentMethods: "Metode Plaćanja",
		resetFilters: "Resetuj",
		applyFilters: "Primeni",
		cartEmpty: "Korpa je prazna.",
		addToCart: "Dodaj u korpu",
		noProducts: "Nema dostupnih proizvoda.",
		viewAllProducts: "Prikazi sve proizvode.",
		add: "Dodaj",
		remove: "Ukloni",
		addedToCart: "dodat u korpu",
		removedFromCart: "uklonjen iz korpe",
		total: "Ukupno:",
		filters: {
			tags: "Tagovi",
			price: "Cena",
			color: "Boja",
			heelHeight: "Visina Štikle",
			size: "Veličina",
			new: "Novo",
			sale: "Rasprodaja",
			women: "Ženske",
			man: "Muške",
			kids: "Dečije",
			colors: {
				red: "Crvena",
				blue: "Plava",
				green: "Zelena",
				black: "Crna",
				white: "Bela",
				yellow: "Žuta",
				purple: "Ljubičasta",
				orange: "Narandžasta",
				brown: "Braon",
				gray: "Siva",
			},
		},
		heading: "Strast Iza Koraka",
		content:
			"U Soul and Sole, verujemo da svaki plesač zaslužuje cipele graciozne poput njihovih pokreta. Naša kolekcija je pažljivo osmišljena da spoji eleganciju, udobnost i preciznost, osiguravajući da možete plesati sa samopouzdanjem. Bilo da nastupate na sceni ili usavršavate tehniku u studiju, naše cipele prate vašu strast na svakom koraku.",
	},
};
