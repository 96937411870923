import "./App.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectScreenSize } from "./store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/themeDesign";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import FilterComponent from "./components//filterComponent/FilterComponent";
import HomePage from "./components/pages/HomePage";
import Footer from "./components/footer/Footer";
import LoadingModal from "./components/other/LoadingModal";
import NotificationContainer from "./components/other/NotificationContainer";
import ScreenSizeListener from "./helpers/ScreenSizeListener";
import ShopPage from "./components/pages/ShopPage";

function App() {
	const [theme] = useState(themeCreation());
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Header />
				{!isSmallScreen && <FilterComponent />}
				<Routes>
					<Route path="/*" element={<HomePage />} />
					<Route path="/shop/*" element={<ShopPage />} />
				</Routes>
				<Footer />
				<LoadingModal />
				<NotificationContainer />
				<ScreenSizeListener />
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
